.hljs {
  color: #000;
  background: #fff;
  display: block;
  overflow-x: auto;
}

.hljs-comment, .hljs-quote, .hljs-variable {
  color: green;
}

.hljs-keyword, .hljs-selector-tag, .hljs-built_in, .hljs-name, .hljs-tag {
  color: #00f;
}

.hljs-string, .hljs-title, .hljs-section, .hljs-attribute, .hljs-literal, .hljs-template-tag, .hljs-template-variable, .hljs-type, .hljs-addition {
  color: #a31515;
}

.hljs-deletion, .hljs-selector-attr, .hljs-selector-pseudo, .hljs-meta {
  color: #2b91af;
}

.hljs-doctag {
  color: gray;
}

.hljs-attr {
  color: red;
}

.hljs-symbol, .hljs-bullet, .hljs-link {
  color: #00b0e8;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

a.ui-button:hover {
  color: #fff;
  text-decoration: none;
}

.documentation-links {
  float: right;
  margin-left: -100%;
  position: relative;
}

.documentation-links a {
  align-items: center;
  margin-left: 24px;
  padding: 6px 0;
  display: inline-flex;
  text-decoration: none !important;
}

.social-link {
  margin-right: 8px;
  text-decoration: none;
}

.console-content {
  height: 230px;
  overflow: auto;
}

.console-header {
  border-bottom: 1px solid #00000014;
  padding: 0 0 16px;
  display: flex;
}

.console-title {
  flex: 1;
  margin: 0 !important;
}

.console-message {
  color: var(--rz-text-color);
  background: var(--rz-info-lighter);
  border-radius: var(--rz-border-radius);
  align-items: baseline;
  margin: 4px 0;
  padding: 2px 0;
  font-size: .875rem;
  display: flex;
}

.console-message-time {
  color: var(--rz-info-dark);
  padding: 2px 8px;
}

body {
  background-color: #f4f5f9;
}

.stage {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.light {
  background-position: 50% -700px;
  background-repeat: no-repeat;
  padding-top: 3rem;
}

.dark {
  background-color: #050a23;
  background-position: 50% 0;
  background-repeat: no-repeat;
  padding-bottom: 3rem;
}

.next a {
  color: #ff504d;
}

.next a:hover {
  color: #ff7371;
  text-decoration: none;
}

.dark a {
  color: #ff9c9a;
}

.dark a:hover {
  color: #ff504d;
}

.next h1, .next h2, .next h3, .next h4, .next h5, .next h6 {
  color: #050a23;
  line-height: 100%;
}

.next .dark, .next .dark h1, .next .dark h2, .next .dark h3, .next .dark h4, .next .dark h5, .next .dark h6, .next .dark p {
  color: #fff;
}

.next h1 {
  letter-spacing: -.02em;
  font-size: 72px;
  font-weight: bold;
}

.next .subtitle {
  margin: 3rem 0 3.75rem;
  font-size: 24px;
  font-weight: 100;
  line-height: 1.3em;
}

.next .subtitle strong {
  font-weight: 700;
}

.next .description {
  margin-bottom: 4rem;
  font-size: 16px;
  font-weight: 300;
  line-height: 100%;
}

.next h3 {
  letter-spacing: -.02em;
  margin-bottom: 1.25rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
}

.next p {
  color: #515256;
  font-size: 18px;
  font-weight: 400;
}

.nav-links a {
  height: 2rem;
  color: #050a23;
  margin: .5rem 0 0 2.5rem;
  line-height: 2rem;
  transition: all .3s;
  display: inline-block;
}

.nav-links a:hover {
  color: #ff504d;
}

.cta-strip {
  margin-top: 4rem;
}

.cta-button {
  letter-spacing: .05em;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  border: 1px solid #050a23;
  border-radius: .75rem;
  margin: 1rem .5rem 0;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-decoration: none;
  transition: all .3s ease-in-out;
  display: inline-block;
  color: #050a23 !important;
}

.dark .cta-button {
  border-color: #ffffff4d;
  color: #fff !important;
}

.cta-button.primary {
  background: #ff504d;
  border-color: #ff504d;
  font-weight: 600;
  color: #fff !important;
}

.cta-button:hover {
  background: #050a23;
  border-color: #050a23;
  text-decoration: none;
  color: #fff !important;
}

.dark .cta-button:hover {
  background: #fff;
  border-color: #fff;
  text-decoration: none;
  color: #050a23 !important;
}

.cta-button:active {
  color: #c2c2c2 !important;
}

.dark .cta-button:active {
  color: #515256 !important;
}

.customers {
  background-color: #fff;
  border-radius: .75rem;
  margin-top: 5rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px #0000000f;
}

.customers h3 {
  letter-spacing: .1em;
  text-transform: uppercase;
  min-width: 110px;
  margin: 2rem 0;
  font-size: 1rem;
  font-weight: 300;
}

.logos {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 0;
  display: flex;
}

.logos img {
  height: 24px;
  margin: 0 12px 32px;
}

.logos .square {
  height: 48px;
}

video {
  box-shadow: 0 12px 20px #00000080;
}

.radzen-features ul {
  margin-top: 0;
  margin-bottom: 0;
}

.radzen-features li {
  cursor: default;
  padding-top: .6rem;
  padding-bottom: .6rem;
  transition: all .3s ease-in-out;
}

.popular-components {
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.popular-components a {
  color: #1151f3;
  border-bottom: 1px solid #0000;
  font-size: 1.125rem;
  font-weight: 600;
  transition: all .2s;
}

.popular-components a:hover {
  color: #0e44cc;
  border-bottom-color: #0e44cc;
}

.popular-components .component-icon {
  width: 32px;
  height: 32px;
  margin: 0 auto 1rem;
  display: block;
}

.all-components ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.all-components li {
  align-items: flex-start;
  padding: .25rem 0;
  display: flex;
}

.all-components h4 {
  margin: 1.25rem 0 .25rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
}

.all-components a {
  color: #1151f3;
  border-bottom: 1px solid #0000;
  line-height: 1rem;
  transition: all .2s;
}

.all-components a:hover {
  color: #0e44cc;
  border-bottom-color: #0e44cc;
}

.all-components .rz-badge {
  margin-top: .0625rem;
  margin-left: .5rem;
  font-size: .5rem;
  line-height: .625rem;
}

.copyright {
  opacity: .2;
  margin-top: 6rem;
  transition: all .4s ease-in-out;
}

.copyright p {
  color: #acafb9;
  font-size: 1rem;
  font-weight: 300;
}

.copyright:hover {
  opacity: 1;
}

p code, ul code {
  display: inline-block;
}

p code, ul code, pre {
  background: #00000014;
  border-radius: .25rem;
  padding: 0 .5rem;
  line-height: 1.5rem;
  font-size: .8125em !important;
}

.body {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.rz-sidebar {
  bottom: 0 !important;
}

.rz-card.docu p, .rz-card.docu ul {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.docu img, .docu svg {
  width: 100%;
  max-width: 700px;
  margin: 4rem auto;
  display: block;
  box-shadow: 0 4px 20px #0000000f;
}

svg.illustration {
  box-shadow: none;
  max-width: 300px;
  min-height: 220px;
}

.example-container {
  max-width: 1440px;
}

.demos-sidebar .rz-panel-menu .rz-navigation-item-icon-children {
  margin-left: auto;
}

.demos-sidebar .rz-panel-menu .rz-badge {
  margin-left: .5rem;
  font-size: 9px;
  line-height: 12px;
}

@media (max-width: 1024px) {
  .documentation-links {
    float: none;
    margin-bottom: 1rem;
    margin-left: 0;
  }

  .documentation-links a {
    margin-left: 0;
    display: flex;
  }
}

@media (max-width: 767px) {
  .nav-links a {
    margin-left: 1rem;
  }
}

@media (max-width: 576px) {
  .next h1 {
    font-size: 3.5rem;
  }

  .all-components h4 {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .all-components a {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .popular-components a {
    width: 7rem;
    margin-bottom: 2rem;
  }

  .customers h3 {
    margin: 1rem 0 0;
  }

  .copyright {
    opacity: 1;
  }
}

.profile-img {
  object-fit: contain;
  object-position: top;
  max-width: 156px;
}

@media (max-width: 576px) {
  .d-none-extra-small {
    display: none !important;
  }
}

.rz-growl-image {
  display: none;
}

.rz-growl-message p {
  white-space: pre-line;
  margin-left: 30px;
  display: flex;
}

/*# sourceMappingURL=index.dcdb17e2.css.map */
